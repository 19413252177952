<template>
  <v-form
    class="public-page-form form justify-center d-flex flex"
    @submit.stop.prevent="resendVerificationEmail">
    <v-card :flat="hasTwoPaneLayout">
      <v-card-text>
        <v-row>
          <v-col class="text-center pb-0">
            <router-link
              v-if="!hasTwoPaneLayout"
              :to="{ name: 'login' }">
              <CyAppPublicLogo class="public-page-form__logo"/>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 text-center">
          <v-col>
            <h5 class="public-page-form__title my-6">
              {{ pageTitle }}
            </h5>
            <div :class="{ 'form__text': action !== 'verifyFirst' }">
              {{ pageBody }}
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="action === 'resendForm'"
          class="mx-4 mt-2 text-center">
          <v-col>
            <v-text-field
              v-model="resendForm.email"
              :label="$t('forms.fieldEmail')"
              :error-messages="resendEmailErrors"
              required
              class="required-field"
              append-icon="email"
              @focus="$v.resendForm.email.$reset()"
              @blur="$v.resendForm.email.$touch()"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="text-center mt-2 mb-6">
            <v-btn
              type="submit"
              color="secondary"
              :class="{ 'cy-button': action === 'resendForm' }"
              :text="action !== 'resendForm'"
              :block="action === 'resendForm'"
              :disabled="isVerificationBtnDisabled"
              :loading="loading">
              {{ $t('resendVerificationEmail') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CyPageAccountVerification',
  validations: {
    resendForm: {
      email: {
        required,
        email,
      },
    },
  },
  data: () => ({
    loading: false,
    resendForm: {
      email: '',
    },
  }),
  computed: {
    ...mapGetters([
      'defaultRoute',
    ]),
    $static () {
      return {
        actions: ['thankYou', 'signupSuccess', 'resendForm', 'verifyFirst'],
      }
    },
    resendEmailErrors () {
      const errors = []
      const { $dirty, email, required } = this.$v.resendForm.email
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!email) errors.push(this.$t('forms.fieldNotEmail'))
      return errors
    },
    action () {
      return _.$get(this.$route.query, 'action', '')
    },
    email () {
      return _.$get(this.$route.params, 'email', '')
    },
    isVerificationBtnDisabled () {
      const { action, resendForm: { email }, resendEmailErrors } = this
      return (action === 'resendForm' && email === '') || !_.isEmpty(resendEmailErrors)
    },
    pageBody () {
      return this.$t(`${this.action}.body`)
    },
    pageTitle () {
      if (this.action === 'resendForm') return ''
      return this.$t(`${this.action}.title`)
    },
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane
    },
  },
  mounted () {
    const { defaultRoute, $static: { actions } } = this
    const action = _.$get(this.$route.query, 'action', '')

    if (!actions.includes(action)) this.$router.push(defaultRoute)
  },
  methods: {
    ...mapActions('auth', [
      'RESEND_VERIFY_EMAIL',
    ]),
    async resendVerificationEmail () {
      const { action } = this
      const email = action === 'signupSuccess' ? localStorage.getItem(LSK.SIGNUP_EMAIL) : this.email || this.resendForm.email

      if (_.isEmpty(email) && action !== 'signupSuccess') {
        this.$router.push({ name: 'accountVerification', query: { action: 'resendForm' } })
        return
      }

      this.loading = true
      await this.RESEND_VERIFY_EMAIL(email)
      await _.$pause(3000)
      this.loading = false

      if (action === 'resendForm') {
        this.$router.push({
          name: 'accountVerification',
          params: { email },
          query: { action: 'thankYou' },
        })
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Account Verification',
        signupSuccess: {
          title: 'Congrats, you have successfully signed up',
          body: `For us to activate your account, we need you to verify your email address. Check your email and click on the "verify email" link. If you didn't receive an email or just can't find it, don't worry - we've got you covered. Just click on the link below.`,
        },
        verifyFirst: {
          title: 'Verify email',
          body: `Looks like you need to verify your email first before logging in .Check your email and click on the "verify email" link. If you can't find the email, don't worry - we've got you covered. Just click on the link below.`,
        },
        thankYou: {
          title: 'Verify email',
          body: 'Thanks. Check your email and click on the "verify email" link.',
        },
        resendForm: {
          body: 'Enter the email address we should send the verification email to:',
        },
        resendVerificationEmail: 'Resend verification email',
      },
      es: {
        title: 'Verificación de Correo Electronico',
        signupSuccess: {
          title: 'Enhorabuena, te ha registrado con éxito',
          body: 'Para que activemos su cuenta, necesitamos que verifique su dirección de correo electrónico. Revise su correo electrónico y haga clic en el enlace "verificar correo electrónico". Si no recibió un correo electrónico o simplemente no puede encontrarlo, no se preocupe, no hay problemas - simplemente haga clic en el enlace de abajo.',
        },
        verifyFirst: {
          title: 'Verificar correo electrónico',
          body: 'Parece que primero debe verificar su correo electrónico antes de iniciar la sesión. Verifique su correo electrónico y haga clic en el enlace "verificar correo electrónico". Si no puede encontrar el correo electrónico, no se preocupe, no hay problemas - simplemente haga clic en el enlace de abajo.',
        },
        thankYou: {
          title: 'Verificar correo electrónico',
          body: 'Gracias. Revise su correo electrónico y haga clic en el enlace "verificar correo electrónico".',
        },
        resendForm: {
          body: 'Ingrese la dirección de correo electrónico a la que debemos enviar el correo electrónico de verificación:',
        },
        resendVerificationEmail: 'Reenviar correo electrónico de verificación',
      },
      fr: {
        title: 'Vérification de compte',
        signupSuccess: {
          title: 'Félicitations, vous vous êtes inscrit avec succès',
          body: `Afin que nous puissions activer votre compte, nous avons besoin que vous vérifiiez votre adresse email. Vérifiez vos emails et cliquez sur le lien "vérifier l'email". Si vous n'avez pas reçu d 'email ou si vous ne le trouvez tout simplement pas, pas de problème - cliquez simplement sur le lien ci-dessous.`,
        },
        verifyFirst: {
          title: 'Vérifier les email',
          body: `Il semble que vous deviez d'abord vérifier votre adresse email avant de vous connecter. Vérifiez votre messagerie et cliquez sur le lien "vérifier l'adresse email". Si vous ne trouvez pas l'email, ne vous inquiétez pas. Il suffit de cliquer sur le lien ci-dessous.`,
        },
        thankYou: {
          title: `Vérification d'email`,
          body: `Merci. Vérifiez votre email et cliquez sur le lien "vérifier l'adresse email".`,
        },
        resendForm: {
          body: `Saisissez l'adresse email à laquelle nous devons envoyer l'email de vérification :`,
        },
        resendVerificationEmail: `Renvoyer l'email de vérification`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin max-width-280 {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.form {
  &__text {
    @include max-width-280;
  }

  .v-card .v-input,
  .v-card .cy-button {
    @include max-width-280;
  }
}

@media screen and (max-width: 745px) {
  .public-page-form__title {
    margin-top: 64px !important;
    margin-left: 0 !important;
    text-align: center !important;
  }
}
</style>
